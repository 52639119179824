import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import getAccessToken from "../app/services/getAccessToken";
import { useLocation, useNavigate } from "react-router-dom";
import getUser from "../app/services/getUser";
import * as Yup from "yup";
import { removeToken } from "../shared/Helpers";
import Loader from "../components/Loader";
import config from "../config";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import getCcl from "../app/services/getCcl";
import sendMail from "../app/services/sendMail";

const decryptEmail = (encryptedEmail) => {
  let result = "";
  for (let i = 0; i < encryptedEmail.length; i++) {
    let charCode = encryptedEmail.charCodeAt(i);
    let decryptedCharCode = charCode - 3;
    result += String.fromCharCode(decryptedCharCode);
  }
  return result;
};

function CclSign() {
  const validationSchema = Yup.object({
    acceptTerms: Yup.bool().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
  });

  const initialValues = {
    acceptTerms: false,
  };
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("customer");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [dealID, setDealID] = useState("");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const decryptAndFetchToken = async () => {
      if (paramValue) {
        try {
          const decryptedEmail = decryptEmail(paramValue);
          const response = await getAccessToken(decryptedEmail);
          const data = response.data;
          const userDetails = await getUser(data.token);
          const dealID = userDetails.data.dealID;
          setDealID(dealID);
          setUserDetails(userDetails.data);
          setIsLoading(false);
        } catch (e) {
          setErrorMessage("User not found");
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } else {
        removeToken();
        navigate("/login");
      }
    };

    decryptAndFetchToken();
  }, [navigate, paramValue]);

  const [data, setData] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      const {
        data: {
          data: { attributes },
        },
      } = await getCcl();
      setData(attributes);
    };
    fetchData();
  }, []);

  const onSubmit = async () => {
    if (dealID) {
      try {
        const payload = {
          dealID: dealID,
        };

        const timelineUrl = await axios.post(
          `${config.ROTA_URL}ccl-complete`,
          payload
        );
        const letterText = data.CCL;
        await sendMail(
          userDetails.email,
          userDetails.name,
          "",
          letterText,
          "ccl",
          "d-c7d6c768551748b08910a8dd8c96717a",
          timelineUrl.data
        );

        navigate("/ccl-completed");
      } catch (error) {
        console.error("Error generating and showing the letter:", error);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <p className="text-danger center">{errorMessage}</p>
          <Loader />
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="ccl-sign container">
                {data && <h2>{data.title}</h2>}
                {data && <ReactMarkdown>{data.description}</ReactMarkdown>}
                {data && (
                  <div className="terms">
                    <ReactMarkdown>{data.CCL}</ReactMarkdown>
                  </div>
                )}
                <div className="form-group">
                  <Field
                    type="checkbox"
                    name="acceptTerms"
                    id="acceptTerms"
                    className="form-check-input"
                  />
                  <label htmlFor="acceptTerms" className="form-check-label">
                    I agree to all the above terms
                    <p>
                      By checking the box, you agree to our terms and we will
                      send you a copy of the agreement.
                    </p>
                  </label>
                  <ErrorMessage
                    name="acceptTerms"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default CclSign;
